import React from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { HEADER_MENU } from 'config/constants/common'

export default function HeaderMenu() {
  const { t } = useTranslation('home')

  return (
    <div className="Header-menu">
      {HEADER_MENU.map((menuItem, index) => (
        <Link key={index} to={`/${menuItem.url}`} activeClassName="active">
          {t(menuItem.label)}
        </Link>
      ))}
    </div>
  )
}
