import ApiRequest from 'services/api'
import { SET_AUTH_DATA, LOG_OUT, SET_SERVICE_AZURE, SET_CLIENT_TZ_DATA } from './actionTypes'
import {
  API_LOGIN,
  API_RELOGIN,
  API_FORCE_PASSWORD,
  API_LOGIN_AZURE_AD,
  API_REFRESH_TOKEN,
  API_LOGOUT_AZURE_AD,
} from 'config/api'
import { AZURE_DATA_SEGMENTS, LS_AZURE, SERVICE_AZURE } from 'config/constants/common'
import * as storage from 'utils/localStorage'
import { getCountryFromTz } from 'utils'

/**
 * Login
 *
 * @param {Object} params Request params
 */
export const login = (params = {}) => () => {
  return ApiRequest.apiPost(API_LOGIN, params)
}

/**
 * Login with azure AD services
 *
 * @param {Object} params Request params
 */
export const loginWithAzureAD = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    ApiRequest.get(
      API_LOGIN_AZURE_AD,
      {},
      (res) => {
        const { data } = res || {}
        const { accessToken = '', data: azureData = '' } = data || {}

        // unauthroized azure AD account
        if (!accessToken || !azureData) {
          resolve({ accessToken, azureData })
          return
        }

        const dataSplit = azureData.split('.') || []
        // can not match format data
        if (dataSplit.length !== AZURE_DATA_SEGMENTS) {
          resolve()
          return
        }

        const strUserData = dataSplit[1] || ''
        const strUserDataDecode = atob(strUserData)
        // eslint-disable-next-line no-unused-vars
        const userInfo = JSON.parse(strUserDataDecode)

        if (storage.getItem(LS_AZURE) !== SERVICE_AZURE) {
          storage.setItem(LS_AZURE, SERVICE_AZURE)
          dispatch(setServiceAzure(true))
        }
        dispatch(setAuthData({ accessToken }))
        resolve()
      },
      (err) => reject(err)
    )
  })
}

/**
 * Get auth info
 */
export const getAuthInfo = () => () => {
  return ApiRequest.apiGet(API_RELOGIN)
}

/**
 * Logout
 */
export const logout = () => (dispatch) => {
  dispatch({ type: LOG_OUT })
}

/**
 * Logout azure AD
 */
export const logoutAzureAD = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    ApiRequest.get(
      API_LOGOUT_AZURE_AD,
      {},
      (res) => {
        const { data } = res || {}
        const { payload } = data || {}
        const { redirectUrl } = payload || {}

        storage.removeItem(LS_AZURE)
        dispatch({ type: LOG_OUT })
        resolve({ redirectUrl })
      },
      () => reject()
    )
  })
}

/**
 * Set auth information
 *
 * @param {Object} authInfo Auth data
 */
export const setAuthData = (authData = {}) => (dispatch) => {
  dispatch({ type: SET_AUTH_DATA, payload: authData })
}

/**
 * Force change password
 *
 * @param {Object} params Request params
 */
export const forceChangePwd = (params = {}) => () => {
  return ApiRequest.apiPost(API_FORCE_PASSWORD, params)
}

/**
 * Set services azure authenticate
 *
 * @param {Boolean} isAzureAuth Use service azure or not
 */
export const setServiceAzure = (isAzureAuth) => (dispatch) => {
  dispatch({ type: SET_SERVICE_AZURE, payload: isAzureAuth })
}

/**
 * Update client timezone data
 */
export const updateClientTzData = () => (dispatch) => {
  const clientTzData = getCountryFromTz()

  dispatch({ type: SET_CLIENT_TZ_DATA, payload: clientTzData })
}

/**
 * Refresh token
 *
 * @param {Object} params Request params
 */
export const requestRefreshToken = (params = {}) => (dispatch) => {
  return new Promise((resolve, reject) => {
    ApiRequest.post(
      API_REFRESH_TOKEN,
      params,
      (res) => {
        const { data } = res || {}
        const { errorCode, payload: authData = {} } = data || {}

        if (errorCode) {
          reject()
          return
        }
        dispatch(setAuthData(authData))
        resolve()
      },
      (err) => reject(err)
    )
  })
}

export default setAuthData
