import { SET_APP_DATA, SET_APP_LOADING, SET_APP_NOTICE_MSG } from './actionTypes'
import * as storage from 'utils/localStorage'
import { LS_EVENT_ID } from 'config/constants/common'

const initialState = {
  eventId: parseInt(storage.getItem(LS_EVENT_ID)) || null,
  appData: null,
  isAppLoading: true,
  noticeData: {
    title: 'lbl_something_went_wrong',
    message: 'lbl_something_went_wrong',
  },
}

const appReducer = (state = initialState, action) => {
  const { type, payload: response } = action

  switch (type) {
    case SET_APP_DATA: {
      const { eventId: oldEventId } = state
      const { eventId } = response

      return {
        ...state,
        eventId: eventId || oldEventId,
        appData: response,
      }
    }
    case SET_APP_LOADING: {
      return {
        ...state,
        isAppLoading: response,
      }
    }
    case SET_APP_NOTICE_MSG: {
      return {
        ...state,
        noticeData: response,
      }
    }
    default:
      return state
  }
}

export default appReducer
