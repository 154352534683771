import { createMuiTheme } from '@material-ui/core'
import { COLORS } from 'config/constants/common'
import styles from 'assets/scss/common/_variable.scss'
const { clPrimary, clWhite } = styles || {}

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: clPrimary || COLORS.clPrimary,
    },
    secondary: {
      main: clWhite || COLORS.clWhite,
    },
    neutral: {
      main: '#F5F5F5',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        '&:focus': {
          outline: 'none',
        },
      },
    },
    MuiIconButton: {
      root: {
        '&:focus': {
          outline: 'none',
        },
      },
    },
    MuiButtonBase: {
      root: {
        '&:focus': {
          outline: 'none',
        },
      },
    },
  },
})

export default muiTheme
