import React from 'react'
import { Box } from '@material-ui/core'
import Header from './Header'
import Footer from './Footer'
import AnimateLink from '../widgets/AnimateLink'
import './index.scss'

export default function Layout(props) {
  const { isUseLayout, children } = props

  return (
    <div className="Layout-area">
      <div className="Layout-content">
        {isUseLayout && <Header />}
        <Box className="Layout-mainContainer">{children}</Box>
      </div>
      {isUseLayout && <Footer />}
      <AnimateLink to="/" />
    </div>
  )
}
