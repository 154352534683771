import { toast } from 'react-toastify'
import i18n from 'utils/i18n'

/**
 * Show toast success
 *
 * @param {String} msg Message error
 */
export const success = (msg) => {
  toast.success(i18n.t(msg))
}

/**
 * Show toast error
 *
 * @param {String} msg Message error
 */
export const error = (msg) => {
  toast.error(i18n.t(msg))
}

/**
 * Show toast warning
 *
 * @param {String} msg Message error
 */
export const warning = (msg) => {
  toast.warning(i18n.t(msg))
}

export default {
  success,
  error,
  warning,
}
