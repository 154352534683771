import moment from 'moment'
import { IS_HIDDEN_VIEWS } from 'config/constants/common'

let timeOutAnalysisRequest = null
let timeIntervalAnalysisRequest = null
let isUpdateEvent = true

/**
 * Fetch api analysis
 *
 * @param {Function} fnRequestApi Function request api
 */
export const fetchApiAnalysis = async (fnRequestApi = () => {}) => {
  if (isUpdateEvent && typeof fnRequestApi === 'function' && !IS_HIDDEN_VIEWS) {
    fnRequestApi()
  }
  isUpdateEvent = true
}

/**
 * Update flag request analysis
 *
 * @param {Boolean} isUpdate Update analysis status
 */
export const setUpdateAnalysis = (isUpdate) => {
  if (isUpdateEvent !== isUpdate) isUpdateEvent = isUpdate
}

/**
 * Fetch api analysis every minute
 *
 * @param {Function} fnRequestApi Request api
 */
export const fetchApiAnalysisEveryMinute = async (fnRequestApi = () => {}) => {
  if (typeof fnRequestApi !== 'function') {
    return
  }
  await fnRequestApi()
  const everySeconds = 60

  let currentSeconds = moment().format('s')
  currentSeconds = currentSeconds >= everySeconds ? 0 : currentSeconds
  const restSeconds = everySeconds - currentSeconds

  // set timeout call request api
  timeOutAnalysisRequest = setTimeout(() => {
    if (restSeconds !== 0) {
      fnRequestApi()
    }

    timeIntervalAnalysisRequest = setInterval(() => {
      fnRequestApi()
    }, everySeconds * 1000)
  }, restSeconds * 1000)
}

/**
 * Clear time interval fetch api analysis
 */
export const clearTimeIntervalFetchApiAnalysis = () => {
  clearInterval(timeIntervalAnalysisRequest)
  clearInterval(timeOutAnalysisRequest)
}
