import React, { memo, useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { AppBar, Box, Container, IconButton, useMediaQuery } from '@material-ui/core'
import { Menu as MenuIcon } from '@material-ui/icons'
import ProfileMenu from './Header/ProfileMenu'
import Language from './Header/Language'
import HeaderMenu from './Header/HeaderMenu'
import DrawerMenu from './Header/DrawerMenu'
import './index.scss'

const Header = memo(() => {
  const { t } = useTranslation(['home', 'login'])
  const [isOpenSlideMenu, setOpenSlideMenu] = useState(false)
  const [anchorElProfile, setAnchorElProfile] = useState(null)
  const [anchorElLanguage, setAnchorElLanguage] = useState(null)
  const isHiddenDropDownMenu = useMediaQuery('(max-width: 1023px)')

  function toggleSlideMenu() {
    setOpenSlideMenu(!isOpenSlideMenu)
  }

  const handleClickLanguage = (event) => {
    setAnchorElLanguage(event.currentTarget)
  }

  const handleCloseLanguage = () => {
    setAnchorElLanguage(null)
  }

  useEffect(() => {
    if (isHiddenDropDownMenu) {
      setAnchorElLanguage(null)
      setAnchorElProfile(null)
    }
  }, [isHiddenDropDownMenu])

  return (
    <AppBar id="Layout-header" className="Header-area" position="fixed">
      <IconButton className="Header-btn_toggle" onClick={toggleSlideMenu}>
        <MenuIcon />
      </IconButton>
      <Link to="/" className="Header-website_link">
        <span className="Icon-ic Icon-logo Header-logo" />
      </Link>
      <Container className="Header-container">
        <span className="Header-website_desc">{t('lbl_slogan_en')}</span>
        <div className="Header-right">
          <Box className="Header-content" display="flex" alignItems="center">
            <HeaderMenu toggleSlideMenu={toggleSlideMenu} />
            {!isHiddenDropDownMenu && (
              <ProfileMenu
                anchorElProfile={anchorElProfile}
                setAnchorElProfile={setAnchorElProfile}
                handleCloseLanguage={handleCloseLanguage}
              />
            )}
            {isHiddenDropDownMenu && (
              <DrawerMenu isOpen={isOpenSlideMenu} toggleSlideMenu={toggleSlideMenu} />
            )}
          </Box>
        </div>
      </Container>
      {!isHiddenDropDownMenu && (
        <Language
          anchorElLanguage={anchorElLanguage}
          handleClickLanguage={handleClickLanguage}
          handleCloseLanguage={handleCloseLanguage}
        />
      )}
    </AppBar>
  )
})

export default Header
