import moment from 'moment-timezone'
import countriesTimes from 'countries-and-timezones'
import { values, isEmpty, includes } from 'lodash'
import { COUNTRIES_TIMEZONES, DEFAULT_TIMEZONE } from 'config/constants/timezone'
import { IS_SIMULATION_DATE, WEBINAR_LIVE_EARLY_MIN } from 'config/constants/common'

export const FORMAT_DATE = {
  YYYY_MM_DD_HH_MM_SS: 'YYYY-MM-DD HH:mm:ss',
  YYYY_MM_DD_HH_MM: 'YYYY-MM-DD HH:mm',
  HH_MM_MMM_DD: 'HH:mm MMM.DD',
  MM_DD_YYYY: 'MM-DD-YYYY',
  YYYY_MM_DD: 'YYYY-MM-DD',
  HH_MM: 'HH:mm',
  YYYY: 'YYYY',
}
const {
  name: defaultZone,
  timeCode: defaultTimeCode,
  utcOffsetStr: defaultUtcOffsetStr,
} = DEFAULT_TIMEZONE

/**
 * Get string time label for calendar event info
 *
 * @param {DateTime} dateTime Calendar event's date time
 * @param {String} currentEventDate Date of select date wanna view event
 * @param {Object} options Options for custom
 */
export const getCalendarEventDate = (dateTime, currentEventDate, options = {}) => {
  if (!dateTime) return ''

  const today = moment(currentEventDate).format('YYYY-MM-DD')
  const { timeFormat = FORMAT_DATE.HH_MM, timezoneCode } = options || {}

  const dateTimeWithTz = getDateTimeWithTz(dateTime, timezoneCode, {
    timeFormat: 'YYYY-MM-DD HH:mm',
  })
  const checkingDate = moment(dateTimeWithTz).format(FORMAT_DATE.YYYY_MM_DD)
  const dateTimeFormat = checkingDate === today ? FORMAT_DATE.HH_MM : timeFormat

  return moment(dateTimeWithTz).format(dateTimeFormat)
}

/**
 * Get event time
 *
 * @param {String} startDate Event start date
 * @param {String} endDate Event end date
 */
export const getEventTime = (startDate, endDate) => {
  const timeFormat = 'HH:mm'
  const startTime = moment(startDate).format(timeFormat)
  const endTime = moment(endDate).format(timeFormat)

  return `${startTime} - ${endTime}`
}

/**
 * Get event status
 *
 * @param {DateTime} startTime Event start time
 * @param {DateTime} endTime Event end time
 * @param {DateTime} options Options for check data
 */
export const isLiveEventStatus = (startTime, endTime, options = {}) => {
  const { currentTime, timeFormat = FORMAT_DATE.YYYY_MM_DD_HH_MM_SS, isOnlyCheckDate = false } =
    options || {}

  const startTimeValue = moment(moment(startTime).format(timeFormat))
  const endTimeValue = moment(moment(endTime).format(timeFormat))
  const currentTimeValue = currentTime
    ? moment(currentTime).format(timeFormat)
    : moment(getCurrentTimeByDefaultTz()).format(timeFormat)

  // convert to moment use for compare
  const currentTimeCompare = moment(currentTimeValue)

  // isOnlyCheckDate use for check current event has same date with checking date
  // if check date => with equals
  // if check time => not contain equals
  const conditionalCheckEndTime = isOnlyCheckDate
    ? currentTimeCompare <= endTimeValue
    : currentTimeCompare < endTimeValue

  // check live event early config minutes
  const conditionalCheckStartTime = isOnlyCheckDate
    ? startTimeValue <= currentTimeCompare
    : startTimeValue <= currentTimeCompare + WEBINAR_LIVE_EARLY_MIN

  return conditionalCheckStartTime && conditionalCheckEndTime
}

/**
 * Get dates from start date and end date
 *
 * @param {Date} startDate State date
 * @param {Date} endDate End date
 * @param {Object} options Options data
 */
export const getDatesFromByRange = (startDate, endDate, options = {}) => {
  const { dateFormat = 'YYYY-MM-DD' } = options || {}
  const checkingDateFormat = 'YYYY-MM-DD'
  const arrDates = []

  // checking by timezone japan
  startDate = moment(startDate).format(checkingDateFormat)
  endDate = moment(endDate).format(checkingDateFormat)
  let currentDate = startDate
  const currentDateByDefaultTz = moment(currentDate)

  while (currentDate <= endDate) {
    arrDates.push({
      label: currentDateByDefaultTz.format(dateFormat),
      value: currentDate,
    })
    currentDate = currentDateByDefaultTz.add(1, 'days').format(checkingDateFormat)
  }

  return arrDates
}

/**
 * Get reference dates (yesterday, today, tomorrow)
 *
 * @param {String} currentDate Current date
 * @param {String} formatDate Format date wanna export
 */
export const getReferenceDates = (currentDate, formatDate = 'YYYY-MM-DD') => {
  currentDate = currentDate || moment()

  const checkingDate = moment(currentDate).format(formatDate)
  const yesterday = moment(currentDate).subtract(1, 'days').format(formatDate)
  const tomorrow = moment(currentDate).add(1, 'days').format(formatDate)

  return [yesterday, checkingDate, tomorrow]
}

/**
 * Get timezone offset from timezone code
 *
 * @param {String} timezoneCode Timezone code
 */
export const getTimezoneOffset = (timezoneCode) => {
  if (!timezoneCode) {
    return '+00:00'
  }

  return timezoneCode.replace('UTC', '')
}

/**
 * Get date time with timezone
 *
 * @param {String} dateTime Datetime
 * @param {String} fromTimezoneCode Timezone code Ex UTC+09:00
 * @param {Object} options Options
 */
export const getDateTimeWithTz = (dateTime, fromTzCode, options = {}) => {
  // toTimezone has format by international's zone, EX: Asia/Tokyo
  const { timeFormat = 'HH:mm', toTimezone = moment.tz.guess(true) } = options || {}
  const tzOffset = getTimezoneOffset(fromTzCode)

  return moment(`${dateTime}${tzOffset}`).tz(toTimezone).format(timeFormat)
}

/**
 * Get date time by format
 *
 * @param {String} dateTime Date time
 * @param {String} timeFormat Time format
 */
export const getDateTimeByFormat = (dateTime, timeFormat = 'YYYY-MM-DD') => {
  return moment(dateTime).format(timeFormat)
}

/**
 * Get simulation current date
 */
export const getSimulationCurrentDate = () => {
  const eventDates = ['2020-10-07', '2020-10-08', '2020-10-09']

  const currentDay = moment.tz(defaultZone).day()
  const currentHms = moment.tz(defaultZone).format('HH:mm:ss')

  // day of week start SUN = 0, MON = 1, etc...
  // if day of week is SAT = 6 => selectedDate = 2020-10-08
  const selectedDateIndex = currentDay === 6 ? 1 : currentDay % 3
  const selectedDate = `${eventDates[selectedDateIndex]} ${currentHms}`

  return moment(selectedDate).format('YYYY-MM-DD HH:mm:ss')
}

/**
 * Convert milliseconds to time
 *
 * @param {Integer} duration Milliseconds
 */
export const msToTime = (duration) => {
  if (!duration || duration < 0) return '00:00'

  const seconds = Math.floor((duration / 1000) % 60)
  const bufferMinute = seconds ? 1 : 0
  const minutesBySeconds = duration / (1000 * 60) + bufferMinute
  let minutes = Math.floor(minutesBySeconds % 60)
  const bufferHour = minutesBySeconds && minutesBySeconds % 61 ? 0 : 1
  let hours = Math.floor(duration / (1000 * 60 * 60) + bufferHour)

  hours = hours < 10 ? '0' + hours : hours
  minutes = minutes < 10 ? '0' + minutes : minutes
  // seconds = seconds < 10 ? '0' + seconds : seconds

  return hours + ':' + minutes
}

/**
 * Get time left
 *
 * @param {String} timeCompare Time compare with current time
 */
export const getTimeLeft = (timeCompare) => {
  const currentTime = getCurrentTimeByDefaultTz()
  const currentTimeMoment = moment(currentTime)
  const timeCompareVal = moment(timeCompare)

  if (timeCompareVal < currentTimeMoment) return null

  return msToTime(Math.abs(timeCompareVal - currentTimeMoment))
}

/**
 * Get country from Tz
 */
export const getCountryFromTz = () => {
  const clientUtcOffsetStr = moment().format('Z')

  // get selected country by resource supported
  let selectedCountry = COUNTRIES_TIMEZONES.find((countryTz) => {
    const { utcOffsetStr } = countryTz

    return utcOffsetStr === clientUtcOffsetStr
  })

  // if has exist selected resource country => return
  if (!isEmpty(selectedCountry)) {
    return selectedCountry
  }

  // get first country has timezone equals client timezone
  const allTimezones = values(countriesTimes.getAllTimezones()) || []
  selectedCountry = allTimezones.find((tzItem) => {
    const { utcOffsetStr } = tzItem || {}

    return utcOffsetStr === clientUtcOffsetStr
  })
  const { country = '' } = selectedCountry || {}

  return { ...selectedCountry, timeCode: country ? `${country}T` : '' } || {}
}

/**
 * Get event time desc
 *
 * @param {String} timezoneCode Timezone Code Ex: UTC+09:00
 * @param {String} originalTimeCode EX: VNT | SGT | JST
 */
export const getEventTimeDesc = (timezoneCode, originalTimeCode) => {
  const tzCodeStr = includes(timezoneCode, 'UTC') ? timezoneCode : `UTC${timezoneCode}`
  const lblTimeCode = originalTimeCode ? ` ${originalTimeCode}` : ''

  return `${defaultTimeCode}${
    timezoneCode !== `UTC${defaultUtcOffsetStr}` && timezoneCode !== defaultUtcOffsetStr
      ? ` (${tzCodeStr}${lblTimeCode})`
      : ''
  }`
}

/**
 * Get current time by default timezone (Ex: Japan)
 *
 * @param {String} dateTime Date time
 */
export const getCurrentTimeByDefaultTz = (dateTime = null) => {
  const currentTime = dateTime ? moment(dateTime) : moment()
  const dateTimeTz = IS_SIMULATION_DATE
    ? moment(getSimulationCurrentDate())
    : currentTime.tz(defaultZone)

  return dateTimeTz.format('YYYY-MM-DD HH:mm:ss')
}
