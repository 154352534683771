import React from 'react'
import { CircularProgress, makeStyles } from '@material-ui/core'
import './index.scss'

const useStyles = makeStyles((theme) => ({
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  circle: {
    strokeLinecap: 'round',
  },
}))

export default function AppLoading(props) {
  const classes = useStyles()

  return (
    <div className="AppLoading-wrapper">
      <div className="AppLoading-root">
        <div className="AppLoading-container">
          <div className="AppLoading-loading">
            <CircularProgress
              variant="determinate"
              className={classes.bottom}
              size={40}
              thickness={4}
              {...props}
              value={100}
            />
            <CircularProgress
              variant="indeterminate"
              disableShrink
              className="AppLoading-top"
              classes={{
                circle: classes.circle,
              }}
              size={40}
              thickness={4}
              {...props}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
