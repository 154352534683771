import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import Layout from '../Layouts/Layout'
import LayoutTour from '../Layouts/LayoutTour'
import AppLoading from 'components/AppLoading/AppLoading'
import { useApp } from 'store/Application'
import { useAuth } from 'store/Auth'
import * as storage from 'utils/localStorage'
import { LS_EVENT_ID, LS_AZURE, SERVICE_AZURE } from 'config/constants/common'
import ConfirmDialog from 'components/widgets/Dialog/ConfirmDialog'
import { getMsgError } from 'utils/validate'
import { setUpdateAnalysis } from 'utils/apis'
import { isVip, isPresenter } from 'utils'

const TIMEOUT_APP_LOADING = 700
let isVerifiedApiAuth = false // use for check role

export default function PageWrapContext(props) {
  const { isAppLoading, setAppLoading, setAppNoticeMsg } = useApp()
  const { authData, setAuthData, getAuthInfo, logoutAzureAD } = useAuth()
  const [errorMsg, setErrorMsg] = useState('')
  const [isUnAuthorized, setUnauthorized] = useState(false)
  const [isClosingDialog, setClosingDialog] = useState(false)
  // use for prevent show home page before welcome page after login
  const [isHiddenAppLoading, setHiddenAppLoading] = useState(false)
  const { location, pageContext, children } = props
  const { pathname = '' } = location
  const {
    isUseLayout = true,
    eventId,
    isCheckVipPresenter,
    isCheckPresenter,
    isCheckVip,
    isTourPage,
  } = pageContext || {}

  // set event id into local storage
  if (eventId) storage.setItem(LS_EVENT_ID, eventId)

  useEffect(() => {
    authenticate()

    // Listen event when user click on browser navigate
    if (typeof window !== 'undefined') {
      window.addEventListener('popstate', () => setUpdateAnalysis(false), false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (pathname === '/welcome' && isHiddenAppLoading && isAppLoading) {
      setAppLoading(false)
      setHiddenAppLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAppLoading, pathname, isHiddenAppLoading])

  useEffect(() => {
    const isAuthVip = isVip(authData)
    const isAuthPresenter = isPresenter(authData)
    // check permission the page had required role vip or presenter
    if (
      isVerifiedApiAuth &&
      ((isCheckVipPresenter && !isAuthVip && !isAuthPresenter) ||
        (isCheckPresenter && !isAuthPresenter) ||
        (isCheckVip && !isAuthVip))
    ) {
      setAppNoticeMsg({ title: 'lbl_no_permission_title', message: 'lbl_no_permission' })
      navigate('/notice')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authData, pathname, isCheckVipPresenter, isCheckPresenter, isCheckVip])

  /**
   * Authenticate
   */
  async function authenticate() {
    try {
      const res = await getAuthInfo()
      isVerifiedApiAuth = true
      const { data } = res || {}
      const { errorCode, payload: authData = {} } = data || {}

      // unauthorized
      if (errorCode) {
        setErrorMsg(getMsgError(errorCode))
        setTimeout(() => setAppLoading(false), TIMEOUT_APP_LOADING)
        return
      }

      // authorized
      setAuthData(authData)
      // if first time login azure
      let firstTimeLoginAzure = false
      if (storage.getItem(LS_AZURE) !== SERVICE_AZURE) {
        storage.setItem(LS_AZURE, SERVICE_AZURE)
        firstTimeLoginAzure = true
      }

      // redirect to welcome page after authenticate with azure and go to home /
      if (firstTimeLoginAzure && pathname === '/') {
        navigate('/welcome')
        setHiddenAppLoading(true) // disable render home's page before render welcome's page
        return
      }

      setTimeout(() => setAppLoading(false), TIMEOUT_APP_LOADING)
    } catch (error) {
      setErrorMsg('error:lbl_relogin_again')
      setAppLoading(false)
      setUnauthorized(true)
      isVerifiedApiAuth = true
    }
  }

  /**
   * Logout with azure AD
   */
  async function logoutWithAzureAD() {
    try {
      const res = await logoutAzureAD()
      const { redirectUrl = '/' } = res || {}

      if (typeof window !== 'undefined') {
        window.location.href = redirectUrl
      }
      setTimeout(() => setAppLoading(false), TIMEOUT_APP_LOADING)
    } catch (e) {
      // Exeption
      setTimeout(() => {
        setAppLoading(false)
        setErrorMsg('error:lbl_relogin_again')
        setUnauthorized(true)
      }, TIMEOUT_APP_LOADING)
    }
  }

  /**
   * Handle close dialog
   */
  async function handleCloseDialog() {
    if (isUnAuthorized && typeof window !== 'undefined') {
      // if unauthorized after logout => reload page, because can not call api logout
      setUnauthorized(false)
      window.location.reload()
      return
    }

    setClosingDialog(true)
    await logoutWithAzureAD()
    setClosingDialog(false)
    setErrorMsg('')
  }

  if (isAppLoading) {
    return <AppLoading />
  }

  // if is tour pages => use layout tour
  if (isTourPage) {
    return <LayoutTour {...props}>{children}</LayoutTour>
  }

  return (
    <Layout {...props} isUseLayout={isUseLayout}>
      {children}
      <ConfirmDialog
        isOpen={!!errorMsg}
        message={errorMsg}
        isProcessing={isClosingDialog}
        onConfirm={handleCloseDialog}
      />
    </Layout>
  )
}
