/* eslint-disable max-len */
import moment from 'moment'
import { some, includes, isEmpty, last, slice, forEach, unionBy, sortBy } from 'lodash'
import { getCurrentTimeByDefaultTz, getDatesFromByRange, getDateTimeWithTz } from './date'
import { DYNAMIC_PAGES, URL_PATTERN, MAP_DB_LANGUAGES, FILE_CODES } from 'config/constants/common'
import { CONTACT } from 'config/constants/panel'
import { DEFAULT_TIMEZONE } from 'config/constants/timezone'
import defaultImg from 'assets/images/default.jpg'

const { name: defaultTz, utcOffsetStr: defaultUtcOffsetStr } = DEFAULT_TIMEZONE

/**
 * Format number
 *
 * @param {Integer} value Value wanna format
 */
export const formatNumber = (value) => {
  if (!value) return 0

  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

/**
 * Check current page is dynamic page or not
 *
 * @param {String} path Current path
 */
export const isDynamicPage = (path = '') => {
  return some(DYNAMIC_PAGES, (dynamicPage) => {
    dynamicPage = String(dynamicPage)
    return includes(path, dynamicPage)
  })
}

/**
 * Get featured webinar
 *
 * @param {Array} webinars List webinars
 * @param {Integer} numOfItems Number of featured webinars we wanna pop
 * @param {String} type Webinar type
 */
export const getFeaturedWebinars = (webinars = [], numOfItems = 1, type) => {
  if (isEmpty(webinars) || numOfItems < 1) {
    // if get only one item => return object
    return numOfItems > 1 ? [] : {}
  }

  const featuredWebinars =
    webinars.filter((webinar) => {
      const { webinarType, startDateTimezone, endDateTimezone } = webinar

      const currentTime = getCurrentTimeByDefaultTz()
      const conditionalType = !type || (type && webinarType === type)

      return (
        ((startDateTimezone <= currentTime && currentTime < endDateTimezone) ||
          currentTime <= startDateTimezone) &&
        conditionalType
      )
    }) || []

  if (numOfItems <= 1) {
    // if get only one item => return object
    const [firstFeaturedWebinar = {}] = featuredWebinars || []
    return isEmpty(firstFeaturedWebinar) ? last(webinars) : firstFeaturedWebinar
  }

  return isEmpty(featuredWebinars)
    ? slice(webinars, -numOfItems, webinars.length) // last numOfItems values
    : slice(featuredWebinars, 0, numOfItems) // first numberOfItems values
}

/**
 * Check url
 *
 * @param {String} url http url
 */
export const checkUrl = (url) => {
  // eslint-disable-next-line max-len
  const reg = new RegExp(
    '^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$'
  )

  return reg.test(url)
}

/**
 * Check browser has scrollbar
 */
export const checkHasScrollbar = () => {
  return typeof window !== 'undefined'
    ? window.innerWidth > document.documentElement.clientWidth
    : false
}

/**
 * Toggle open menu
 *
 * @param {Boolean} isOpen Open or not
 */
export const toggleHeaderOpenMenu = (isOpen) => {
  const eleHeader = document.getElementById('Layout-header')

  isOpen ? eleHeader.classList.add('openMenu') : eleHeader.classList.remove('openMenu')
}

/**
 * Calculate time remaining
 *
 * @param {String} startTime Time start
 * @param {String} endTime Time end
 */
export const calculateTimeRemaining = (startTime, endTime) => {
  const currentTime = moment()
  const calculateTimeStart = moment(startTime)
  const calculateTimeEnd = moment(endTime)

  const diffStart = currentTime.diff(calculateTimeStart, 'seconds')
  const diffEnd = calculateTimeEnd.diff(currentTime, 'seconds')

  let secondsDiff
  if (diffStart < 0) {
    secondsDiff = calculateTimeStart.diff(currentTime, 'seconds')
  } else if (diffEnd > 0) {
    secondsDiff = diffEnd
  }

  if (secondsDiff) {
    let minutesDiff = Math.ceil(secondsDiff / 60)

    const days = parseInt(minutesDiff / 24 / 60)
    minutesDiff = minutesDiff - days * 24 * 60
    const hours = parseInt(minutesDiff / 60)
    const minutes = minutesDiff % 60

    return days + ' days ' + hours + ' hours ' + minutes + ' minutes'
  } else {
    return 'Ended'
  }
}

/**
 * Get tag items in list tag groups by tagGroupId & list tagItemIds
 *
 * @param {Array} data List tag groups contain list tag items
 * @param {Integer} tagGroupId Tag group Id
 * @param {Object} options Tag items ids wanna filter | Length of items wanna filter
 */
export const getTagItems = (data = [], tagGroupId = undefined, options = {}) => {
  const { tagItemIds = [], numOfItems } = options || {}

  return data.reduce((finalData, tagGroupItem) => {
    const { tagGroupId: itemTagGroupId, ecmsTagListsByTagGroupId } = tagGroupItem || {}
    let filterData = []

    if (tagGroupId === itemTagGroupId) {
      const { nodes: tagItems = [] } = ecmsTagListsByTagGroupId || {}
      filterData =
        tagItems.reduce((listTagItemIds, tagItem, index) => {
          const { tagListId } = tagItem || {}

          // if get items by length of items
          if (index < numOfItems) {
            return [...listTagItemIds, tagItem]
          }

          // get items by item ids
          return includes(tagItemIds, tagListId) ? [...listTagItemIds, tagItem] : listTagItemIds
        }, []) || []
    }

    return [...finalData, ...filterData]
  }, [])
}

/**
 * Get featured image
 *
 * @param {Array} files Files
 * @param {Array} featureImgCodes Featured image code
 * @param {Object} options Options
 */
export const getFeaturedImg = (files = [], featureImgCodes = [FILE_CODES.FE1], options = {}) => {
  const { defaultImage = defaultImg } = options || {}
  const featuredImgs = files.filter((file) => includes(featureImgCodes, file.keyCode)) || []
  const [featuredImg] = featuredImgs
  const { url = defaultImage } = featuredImg || {}
  // 
  return url
}

/**
 * Get featured images
 *
 * @param {Array} files Files
 * @param {Boolean} isTruncate Check truncate file code
 */
export const getFeaturedImgs = (files = [], isTruncate = false) => {
  const arrFileCodes = []

  return (
    files.filter((file) => {
      const { keyCode } = file || {}

      let isValid = false
      const conditionalTruncate = !isTruncate || (isTruncate && !includes(arrFileCodes, keyCode))
      if (includes([FILE_CODES.FE1, FILE_CODES.FE2], keyCode) && conditionalTruncate) {
        arrFileCodes.push(keyCode)
        isValid = true
      }

      return isValid
    }) || []
  )
}

/**
 * Get featured audio
 *
 * @param {Array} files Files
 */
export const getFeaturedAudio = (files = []) => {
  const featureAudios = files.filter((file) => includes([FILE_CODES.AUD], file.keyCode)) || []
  const [featuredAudio] = featureAudios
  const { url } = featuredAudio || {}

  return url
}

/**
 * Get featured video
 *
 * @param {Array} files Files
 */
export const getFeaturedVideo = (files = []) => {
  const featureVideos = files.filter((file) => includes([FILE_CODES.VID], file.keyCode)) || []
  const [featuredVideo] = featureVideos
  const { url } = featuredVideo || {}

  return url
}

/**
 * Get list dates get from webinar event dates
 *
 * @param {Array} eventWebinars List event webinars
 * @param {Object} options Options
 */
export const getDatesFromWebinarEvents = (eventWebinars = [], options = {}) => {
  if (isEmpty(eventWebinars)) {
    return []
  }

  const { filterTypes = [], dateFormat = 'YYYY-MM-DD' } = options || {}
  let listDates = []
  forEach(eventWebinars, (webinarItem) => {
    const { webinarType, startDateTimezone, endDateTimezone } = webinarItem

    // do not check webinar has type is invalid
    if (!isEmpty(filterTypes) && !includes(filterTypes, webinarType)) {
      return
    }

    const arrDates = getDatesFromByRange(startDateTimezone, endDateTimezone, { dateFormat })
    listDates = unionBy([...listDates, ...arrDates], 'value')
  })
  listDates = sortBy(listDates)

  return listDates
}

/**
 * Get zoom URL
 *
 * @param {Object} data Zoom setting data
 */
export const getZoomURL = (data = {}) => {
  const { zoomLink = '' } = data || {}

  if (!zoomLink || (typeof zoomLink === 'string' && !zoomLink.trim())) return ''

  return zoomLink
}

/**
 * Join zoom meeting
 *
 * @param {Object} data Zoom setting data
 */
export const joinZoomMeeting = (data = {}) => {
  if (typeof window === 'undefined') return

  window.open(getZoomURL(data), '_blank')
}

/**
 * Get webinar video url
 *
 * @param {String} url Webinar URL
 */
export const getWebinarVideoURL = (url = '') => {
  if (!url || (typeof url === 'string' && !url.trim())) return ''

  return url
}

/**
 * Get URL with check http|https
 *
 * @param {String} url URL string
 */
export const getURL = (url = '') => {
  if (!url || (typeof url === 'string' && !url.trim())) return ''

  return URL_PATTERN.test(url) ? url : `http://${url}`
}

/**
 * Get mail data
 *
 * @param {Mixed} to To sb
 * @param {Mixed} cc Mention to sb
 * @param {Mixed} subjectIn Mail subject
 */
export const getMailTo = (to, cc, subjectIn) => {
  let toValue = ''
  let ccValue = ''
  const subject = subjectIn || CONTACT.SUBJECT

  if (to) {
    if (typeof to === 'string') {
      toValue = to
    } else if (Array.isArray(to) && to.length > 0) {
      toValue = to.join(',')
    }
  }

  if (cc) {
    if (typeof cc === 'string') {
      ccValue = cc
    } else if (Array.isArray(cc) && cc.length > 0) {
      ccValue = cc.join(',')
    }
  }

  return `mailto:${toValue}?subject=${subject}${ccValue && `&cc=${ccValue}`}`
}

/**
 * Get list webinars by lang && sort by start date
 *
 * @param {Array} webinars List of webinars
 * @param {String} lang Language
 * @param {Object} options Options
 */
export const getWebinarsByLang = (webinars = [], lang = MAP_DB_LANGUAGES.en, options = {}) => {
  if (isEmpty(webinars)) return []

  const timeFormat = 'YYYY-MM-DD HH:mm:ss'
  const { filterTypes = [] } = options || {}

  const webinarsByLang = webinars.reduce((listWebinars, webinar) => {
    const { webinarType, languageCode, startDate, endDate, ecmsTimezoneByTimezoneId } =
      webinar || {}
    const { timezoneCode } = ecmsTimezoneByTimezoneId || {}

    // if valid language, timer and filters
    if (
      languageCode === lang &&
      startDate &&
      endDate &&
      moment(startDate) <= moment(endDate) &&
      (isEmpty(filterTypes) || includes(filterTypes, webinarType))
    ) {
      // convert start date, end date by config timezone
      const startDateTimezone = getDateTimeWithTz(startDate, timezoneCode, {
        timeFormat,
        toTimezone: defaultTz,
      })
      const endDateTimezone = getDateTimeWithTz(endDate, timezoneCode, {
        timeFormat,
        toTimezone: defaultTz,
      })

      return [
        ...listWebinars,
        {
          ...webinar,
          startDateTimezone,
          endDateTimezone,
        },
      ]
    }
    return listWebinars
  }, [])

  // sort by startDate with default timezone EX: Asia/Tokyo
  return webinarsByLang.sort((a, b) => {
    const { startDateTimezone: aStartDate, endDateTimezone: aEndDate } = a
    const { startDateTimezone: bStartDate, endDateTimezone: bEndDate } = b

    const isCompareStartDate = aStartDate !== bStartDate
    const aTimer = isCompareStartDate ? aStartDate : aEndDate
    const bTimer = isCompareStartDate ? bStartDate : bEndDate

    return moment(aTimer) - moment(bTimer)
  })
}

/**
 * Get list exhibitions by lang
 *
 * @param {Array} exhibitions List of exhibitions
 * @param {String} lang Language
 */
export const getExhibitionsByLang = (exhibitions = [], lang = MAP_DB_LANGUAGES.en) => {
  return exhibitions.filter(({ languageCode }) => languageCode === lang) || []
}

/**
 * Get DOM Element exhibition id
 *
 * @param {Integer} trackId Track Id
 * @param {Integer} topicId Topic Id
 */
export const getEleExhibitionId = (trackId, topicId) => {
  return `Track${trackId}Topic${topicId}`
}

/**
 * Get featured webinar title
 *
 * @param {String} title Webinar title
 */
export const getFeatureWebinarTitle = (title = '') => {
  if (typeof title !== 'string') return ''

  const MAX_LENGTH = 79
  if (title.length > MAX_LENGTH) {
    return `${title.substr(0, MAX_LENGTH)}...`
  }
  return title
}

/**
 * Get webinar time by client timezone
 *
 * @param {String} startDate Webinar start date
 * @param {String} endDate Webinar end date
 */
export const getWebinarTimeByClientTz = (startDate, endDate) => {
  const timeFormat = 'YYYY-MM-DD HH:mm:ss'
  const clientStartDate = getDateTimeWithTz(startDate, defaultUtcOffsetStr, {
    timeFormat,
  })
  const clientEndDate = getDateTimeWithTz(endDate, defaultUtcOffsetStr, { timeFormat })

  return { clientStartDate, clientEndDate }
}

export default formatNumber
