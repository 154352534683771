import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { getListWebinarsViews, getListPanelsViews, getTotalAnalysis } from './actions'

export const useAnalysis = () => {
  const dispatch = useDispatch()
  const analysisReducer = useSelector(({ analysisReducer }) => analysisReducer, shallowEqual)

  return {
    ...analysisReducer,
    getListWebinarsViews: () => dispatch(getListWebinarsViews()),
    getListPanelsViews: () => dispatch(getListPanelsViews()),
    getTotalAnalysis: () => dispatch(getTotalAnalysis()),
  }
}
