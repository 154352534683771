import React from 'react'
import { Button } from '@material-ui/core'
import './index.scss'

export default function CommonButton(props) {
  const { label = '', children, className = '', isRounded, hasBoxShadow, ...rest } = props

  return (
    <Button
      {...rest}
      className={`Button-container ${isRounded && 'rounded'} ${
        hasBoxShadow && 'box-shadow'
      } ${className}`}
      variant="contained"
    >
      {children ? children : <span dangerouslySetInnerHTML={{ __html: label }} />}
    </Button>
  )
}
