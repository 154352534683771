import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { LANGUAGE_CODES, LS_LANGUAGE } from 'config/constants/common'
import en from 'assets/lang/en.json'
import jp from 'assets/lang/jp.json'

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: LANGUAGE_CODES[1],
    returnEmptyString: '',
    keySeparator: false,
    resources: { en, jp },
    whitelist: LANGUAGE_CODES,
    // i18next cache
    cache: {
      enabled: true,
      prefix: LS_LANGUAGE,
      expirationTime: 7 * 24 * 60 * 60 * 1000,
    },
    // cache language in local storage and cookies
    detection: {
      order: ['localStorage', 'cookie'],
      caches: ['localStorage', 'cookie'],
      lookupCookie: 'i18next',
      lookupLocalStorage: LS_LANGUAGE,
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,
    },
    // react i18next special options (optional)
    // override if needed - omit if ok with defaults
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true,
    },
  })

export default i18n
