import React from 'react'
import { useTranslation } from 'react-i18next'
import { Menu, MenuItem } from '@material-ui/core'
import { LANGUAGES } from 'config/constants/common'
import './index.scss'

const Language = (props) => {
  const { i18n } = useTranslation('home')
  const { anchorElLanguage, handleClickLanguage, handleCloseLanguage } = props
  const currentLang = i18n.language

  /**
   * Handle change language
   *
   * @param {String} lang Language code
   */
  function handleChangeLanguage(lang) {
    if (i18n.language !== lang) {
      i18n.changeLanguage(lang)
    }
    handleCloseLanguage()
  }

  return (
    <div className="Language-area">
      <div className="Language-current_lang" onClick={handleClickLanguage}>
        <span className="Language-current_lang-txt">{currentLang}</span>
      </div>
      <Menu
        className="Language-menu"
        anchorEl={anchorElLanguage}
        open={Boolean(anchorElLanguage)}
        onClose={handleCloseLanguage}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {LANGUAGES.map((langItem, index) => (
          <MenuItem onClick={() => handleChangeLanguage(langItem.code)} key={index}>
            <span className={`Icon-ic Icon-flag-${langItem.code} Language-icon`} />
            <p className="Language-current_lang-txt">{langItem.code}</p>
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default Language
