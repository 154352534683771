export const TYPE_OF_LIST = {
  ALL_EXHIBITION: '/exhibitions',
  MY_LIST: '/mylist',
}

export const MY_LIST_ELEMENT_TYPE = {
  EXHIBITION: 'exhibition',
  WEBINAR: 'webinar',
}

export const CONTACT = {
  TO: 'nohostmailhere@something.com',
  CC: 'koho@rdpo.jp.nec.com',
  SUBJECT: '[OH 2020 Inquiry]',
}
