import ApiRequest from 'services/api'
// import toast from 'utils/toast'
import {
  API_GET_LIST_WEBINARS_VIEWS,
  API_GET_LIST_PANELS_VIEWS,
  API_GET_TOTAL_ANALYSIS,
} from 'config/api'
import { GET_LIST_WEBINARS_VIEWS, GET_LIST_PANELS_VIEWS, GET_TOTAL_ANALYSIS } from './actionTypes'

/**
 * Get list webinars views
 */
export const getListWebinarsViews = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    return ApiRequest.get(
      API_GET_LIST_WEBINARS_VIEWS,
      {},
      (res) => {
        const { data } = res || {}
        const { errorCode, payload = {} } = data || {}

        // has any errors
        if (errorCode) {
          // toast.error('error:lbl_fetch_webinars_views')
          reject()
          return
        }

        dispatch({ type: GET_LIST_WEBINARS_VIEWS, payload })
        resolve()
      },
      (err) => {
        // toast.error('error:lbl_fetch_webinars_views')
        reject()
      }
    )
  })
}

/**
 * Get list panels views
 */
export const getListPanelsViews = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    return ApiRequest.get(
      API_GET_LIST_PANELS_VIEWS,
      {},
      (res) => {
        const { data } = res || {}
        const { errorCode, payload = {} } = data || {}

        // has any errors
        if (errorCode) {
          // toast.error('error:lbl_fetch_panels_views')
          reject()
          return
        }

        dispatch({ type: GET_LIST_PANELS_VIEWS, payload })
        resolve()
      },
      (err) => {
        // toast.error('error:lbl_fetch_panels_views')
        reject()
      }
    )
  })
}

/**
 * Get total analysis
 */
export const getTotalAnalysis = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    return ApiRequest.get(
      API_GET_TOTAL_ANALYSIS,
      {},
      (res) => {
        const { data } = res || {}
        const { errorCode, payload = {} } = data || {}

        // has any errors
        if (errorCode) {
          // toast.error('error:lbl_fetch_total_analysis')
          reject()
          return
        }

        dispatch({ type: GET_TOTAL_ANALYSIS, payload })
        resolve()
      },
      (err) => {
        // toast.error('error:lbl_fetch_total_analysis')
        reject()
      }
    )
  })
}
