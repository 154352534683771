export const DYNAMIC_PAGES = ['/webinars', '/exhibitions', '/ExhibitList']
export const LANG_EN = 'en'
export const LANG_JP = 'jp'
export const LANGUAGE_CODES = [LANG_EN, LANG_JP]
export const LANGUAGES = [
  { lang: 'English', code: LANG_EN, displayCode: 'ENG' },
  { lang: 'Japan', code: LANG_JP, displayCode: 'JPN' },
]
export const COLORS = {
  clPrimary: '#333',
  clSecond: '#002774',
  clWhite: '#fff',
}
export const WEBINAR_TYPES = {
  NON_PANEL: 'Non-Panel',
  PANEL: 'Panel',
  UNLISTED: 'Panel-Unlisted',
}
export const ACCESS_CODE = process.env.GATSBY_ACCESS_CODE
export const IS_USE_LOGOUT = !!(parseInt(process.env.GATSBY_USE_LOGOUT) || 0)
export const IS_USE_DOWNLOAD = !!(parseInt(process.env.GATSBY_USE_DOWNLOAD) || 0)
export const IS_HIDDEN_VIEWS = !!(parseInt(process.env.GATSBY_HIDDEN_VIEWS) || 0)
export const IS_PRESENTER_USE_WEBINAR_TABS = !!(
  parseInt(process.env.GATSBY_PRESENTER_USE_WEBINAR_TABS) || 0
)
export const IS_USE_AUDIO_SLIDER = !!(parseInt(process.env.GATSBY_USE_AUDIO_SLIDER) || 0)
// config webinar start before main event N minutes
export const WEBINAR_LIVE_EARLY_MIN =
  (parseFloat(process.env.GATSBY_WEBINAR_LIVE_EARLY_MIN) || 0) * 60000
export const FAVORITE_TYPES = {
  EXHIBITION: 'exhibition',
  WEBINAR: 'webinar',
}
export const HEADER_MENU = [
  { label: 'lnk_exhibition_en', url: 'exhibitions' },
  { label: 'lnk_webinar_en', url: 'webinars' },
  { label: 'lnk_concept_en', url: 'concept' },
  { label: 'lnk_guiline_en', url: 'guideline' },
]
export const TAG_GROUPS = {
  TAG_PROCESS: 1,
  TAG_SOCIAL_VALUE: 2,
  TAG_TECHNOLOGY: 3,
}
export const FILE_CODES = {
  FE1: 'FE1',
  FE2: 'FE2',
  FE3: 'FE3',
  ATT: 'ATT',
  AUD: 'AUD',
  VID: 'VID',
}
export const AZURE_DATA_SEGMENTS = 3
export const DEFAULT_EXPIRE_TOKEN = 3600000
export const REQ_ERROR_CODES = {
  success: 0,
  invalidRequest: 1, // Request wrong: wrong query parameters or body value
  failedQuery: 2, // Maybe code cause query exception or failed to query from Db
  loginCognitoUnknow: 3, // Something wrong with cognito,
  unauthorized: 4, // Unauth: invalid token, expires token, wrong username password
  invalidUserRole: 5, // User role is invalid to query this request
  loginUserInactive: 6, // Error user inactive
  loginUserNotExist: 7, // User is not exist on DB
  loginUserType: 8, // Can't check user type on DB
  errUserHasNoEmail: 9, // User has no email address
  errUserHasNoOrg: 10, // User doesn't belong to any origanization
}
export const SERVICE_AZURE = '1'
export const URL_PATTERN = /^((http|https):\/\/)/
// Map website language with data get from db
export const MAP_DB_LANGUAGES = {
  [LANG_EN]: 'en',
  [LANG_JP]: 'ja',
}
// For show language code by data get from database
export const MAP_LANGUAGES_BY_DB = {
  en: LANG_EN,
  ja: LANG_JP,
}
// LOCAL STORAGE
export const LS_TOKEN = 'TOKEN'
export const LS_EVENT_ID = 'EVENT_ID'
export const LS_LANGUAGE = 'LANGUAGE'
export const LS_AZURE = 'AZURE_AD'

export const TRACKING = {
  ACTION: {
    VIEW: 'VIEW',
    ZOOM: 'ZOOM',
    WATCH: 'WATCH',
    LISTEN: 'LISTEN',
    DOWNLOAD: 'DOWNLOAD',
    SAVE: 'SAVE',
    LOGIN: 'LOGIN',
  },
  TIMESTAMP_FORMAT: 'YYYY-MM-DDTHH:mm:ssZ',
  SOURCE: {
    HOME: 'home',
    CONCEPT: 'concept',
    GUIDELINE: 'guideline',
    EXHIBITIONS: 'exhibitionslist',
    EXHIBITION_DETAIL: 'exhibition',
    WEBINARS: 'webinarslist',
    WEBINAR_DETAIL: 'webinar',
    MY_LIST: 'mylist',
    VIP_WELCOME: 'vipwelcome',
    PRESENTATION: 'presentation',
    WELCOME: 'welcome',
    TOUR: {
      WELCOME: 'tour_welcome',
      EXHIBIT_LIST: 'tour_exhibitlist',
      EXHIBIT_DETAIL: 'tour_exhibitdetail',
    },
  },
}
export const FORMAT_VIDEO_CAN_USE_MP4 = ['mp4', 'mkv']
export const ZONE_FOR_JAPAN = 'Asia/Tokyo'
export const FOOTER_LINK_NEC = {
  [LANG_EN]: 'https://www.nec.com/en/global/rd/',
  [LANG_JP]: 'https://jpn.nec.com/rd/',
}
export const TAB_DATE_FORMAT = 'MMM. DD ddd'
export const IS_SIMULATION_DATE = 0

// ENV RESOURCES
export const HOME_VIDEO_INTRO_URL = process.env.GATSBY_HOME_VIDEO_INTRO_URL
export const TOUR_WELCOME_VIDEO_URL = process.env.GATSBY_TOUR_WELCOME_VIDEO_URL
