import React, { memo } from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { AppBar, Box, Container } from '@material-ui/core'

const HeaderTour = memo((props) => {
  const { t } = useTranslation('tour')
  const { location, pageContext, tourCode: defaultTourCode, isTransparent } = props
  const { tourCode } = pageContext || {}
  const { pathname = '' } = location || {}
  const listParams = pathname.split('/')
  const paramTourCode = !isEmpty(listParams) && listParams.length > 1 ? listParams[2] : ''
  const linkTourCode = tourCode || defaultTourCode || paramTourCode
  const menu = [
    { label: 'lbl_top_page_en', url: `/VIP/${linkTourCode}/Welcome` },
    { label: 'lbl_exhibition_list_en', url: `/VIP/${linkTourCode}/ExhibitList` },
  ]

  return (
    <AppBar
      id="HeaderTour-area"
      className={`HeaderTour-area ${isTransparent && 'transparent'}`}
      position="fixed"
    >
      <div className="HeaderTour-box">
        <Link to={`/VIP/${linkTourCode}/Welcome`} className="HeaderTour-websiteName">
          <span className="Icon-ic Icon-logo Header-logo" />
        </Link>
        <Container className="HeaderTour-container">
          <span className="HeaderTour-desc">
            <span className="HeaderTour-desc_pc">{t('lbl_vip_presentation_en')} - </span>
            {t('home:lbl_slogan_en')}
          </span>
          <Box className="HeaderTour-links" display="flex" alignItems="center">
            {menu.map((menuItem, index) => (
              <Link key={index} to={menuItem.url} activeClassName="active">
                {t(menuItem.label)}
              </Link>
            ))}
          </Box>
        </Container>
        <div className="HeaderTour-after" />
      </div>
    </AppBar>
  )
})

export default HeaderTour
