import { combineReducers } from 'redux'
import { authReducer } from './Auth'
import { appReducer } from './Application'
import { referenceReducer } from './Reference'
import { analysisReducer } from './Analysis'

export default combineReducers({
  authReducer,
  appReducer,
  referenceReducer,
  analysisReducer,
})
