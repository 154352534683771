export const DEFAULT_TIMEZONE = {
  name: 'Asia/Tokyo',
  utcOffsetStr: '+09:00',
  country: 'JP',
  timeCode: 'JST',
  utcOffset: 540,
}

export const COUNTRIES_TIMEZONES = [
  DEFAULT_TIMEZONE,
  {
    name: 'Asia/Ho_Chi_Minh',
    utcOffsetStr: '+07:00',
    country: 'VN',
    timeCode: 'VNT',
    utcOffset: 420,
  },
  {
    name: 'Asia/Singapore',
    utcOffsetStr: '+08:00',
    country: 'SG',
    timeCode: 'SGT',
    utcOffset: 480,
  },
]
