import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { isEmpty } from 'lodash'
import {
  login,
  logout,
  setAuthData,
  getAuthInfo,
  forceChangePwd,
  loginWithAzureAD,
  setServiceAzure,
  logoutAzureAD,
  updateClientTzData,
} from './actions'

export const useAuth = () => {
  const dispatch = useDispatch()
  const authReducer = useSelector(({ authReducer }) => authReducer, shallowEqual)
  const { authData = {} } = authReducer || {}

  return {
    ...authReducer,
    isAuth: !isEmpty(authData),
    login: (params) => dispatch(login(params)),
    getAuthInfo: () => dispatch(getAuthInfo()),
    forceChangePwd: (params) => dispatch(forceChangePwd(params)),
    logout: () => dispatch(logout()),
    setAuthData: (authData) => dispatch(setAuthData(authData)),
    loginWithAzureAD: () => dispatch(loginWithAzureAD()),
    setServiceAzure: (isAzureAuth) => dispatch(setServiceAzure(isAzureAuth)),
    updateClientTzData: () => dispatch(updateClientTzData()),
    logoutAzureAD: () => dispatch(logoutAzureAD()),
  }
}
