import React from 'react'
import { Link, navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { Drawer, List, ListItem, Avatar } from '@material-ui/core'
import { LANGUAGES, HEADER_MENU, IS_USE_LOGOUT } from 'config/constants/common'
import { useAuth } from 'store/Auth'
import { isPresenter as checkPresenter, isVip as checkVip } from 'utils'
import toast from 'utils/toast'
import './index.scss'

const menuHome = { label: 'lbl_home_en', url: '' }
const menuMyList = { label: 'lbl_my_list_en', url: 'mylist' }
const menuVIP = { label: 'lbl_vip_welcome_en', url: 'VIPwelcome' }
const menuPresenter = { label: 'lbl_presentation_en', url: 'presenter' }

export default function DrawMenu(props) {
  const { t, i18n } = useTranslation('home')
  const { authData, logoutAzureAD } = useAuth()
  const isPresenter = checkPresenter(authData)
  const isVip = checkVip(authData)
  const { isOpen, toggleSlideMenu } = props
  const { language: currentLang } = i18n
  const { displayName = '' } = authData || {}

  /**
   * On click logo
   */
  function onClickLogo() {
    toggleSlideMenu(false)
    navigate('/')
  }

  /**
   * Handle change language
   *
   * @param {String} lang Language code
   */
  function handleChangeLanguage(lang) {
    if (currentLang !== lang) {
      i18n.changeLanguage(lang)
      toggleSlideMenu(false)
    }
  }

  /**
   * Handle logout
   */
  async function handleLogout() {
    try {
      const res = await logoutAzureAD()
      const { redirectUrl = '/' } = res || {}

      if (typeof window !== 'undefined') {
        window.location.href = redirectUrl
      }
    } catch (e) {
      // Exeption
      toast.error('error:lbl_something_went_wrong')
    }
  }

  const listMenu = [menuHome, ...HEADER_MENU, menuMyList]
  if (isPresenter) {
    listMenu.push(menuPresenter)
  }
  if (isVip) {
    listMenu.push(menuVIP)
  }

  return (
    <Drawer className="DrawMenu-area" anchor="left" open={isOpen} onClose={toggleSlideMenu}>
      <div className="DrawMenu-header">
        <span className="Icon-ic Icon-logo DrawMenu-logo" onClick={onClickLogo} />
        <span>{t('home:lbl_slogan_en')}</span>
      </div>
      <List dense className="DrawMenu-list">
        {listMenu.map((menuItem, index) => (
          <ListItem key={index}>
            <Link
              to={`/${menuItem.url}`}
              activeClassName="active"
              onClick={toggleSlideMenu}
              className="DrawMenu-item"
            >
              {t(menuItem.label)}
            </Link>
            <hr />
          </ListItem>
        ))}
        <ListItem>
          <span className="DrawMenu-language_container">
            <span className="DrawMenu-language_title">{t('common:lbl_language_en')}</span>
            <span className="DrawMenu-language_list">
              {LANGUAGES.map((langItem, index) => (
                <span
                  key={index}
                  onClick={() => handleChangeLanguage(langItem.code)}
                  className={`${currentLang === langItem.code && 'active'}`}
                >
                  {langItem.displayCode}
                </span>
              ))}
            </span>
          </span>
        </ListItem>
      </List>
      <div className="DrawMenu-user">
        <List dense className="DrawMenu-user_options">
          <ListItem>
            <span className="DrawMenu-user_wrapper">
              <Avatar className="DrawMenu-user_ava" />
              {displayName}
            </span>
            {IS_USE_LOGOUT && <hr style={{ margin: 0, marginLeft: 30 }} />}
          </ListItem>
          {IS_USE_LOGOUT && (
            <ListItem>
              <span>
                <span className="DrawMenu-btn_signout" onClick={() => handleLogout()}>
                  {t('lbl_profile_signout_en')}
                </span>
              </span>
            </ListItem>
          )}
        </List>
      </div>
    </Drawer>
  )
}
