import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  CircularProgress,
} from '@material-ui/core'
import Button from 'components/widgets/Button'
import './index.scss'

export default function ConfirmDialog(props) {
  const { t } = useTranslation('error')
  const {
    isOpen,
    message = '',
    isProcessing,
    btnLabel = 'common:lbl_ok',
    onConfirm = () => {},
  } = props

  return (
    <div>
      <Dialog
        className="CommonDialog-container"
        open={isOpen}
        aria-labelledby="ConfirmDialog-title"
        aria-describedby="ConfirmDialog-msg"
      >
        <DialogContent>
          <DialogContentText id="ConfirmDialog-msg">{t(message)}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onConfirm}>
            {isProcessing ? <CircularProgress size={24} color="secondary" /> : t(btnLabel)}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
