const isBrowser = typeof window !== 'undefined'

/**
 * Set item into local storage
 *
 * @param {String} key Key
 * @param {Mixed} value Value
 */
export const setItem = (key, value) => {
  if (!isBrowser) return

  localStorage.setItem(key, value)
}

/**
 * Get item data from local storage
 *
 * @param {String} key Key
 */
export const getItem = (key) => {
  if (!isBrowser) return

  return localStorage.getItem(key)
}

/**
 * Remove item data from local storage
 *
 * @param {String} key Key
 */
export const removeItem = (key) => {
  if (!isBrowser) return

  localStorage.removeItem(key)
}

/**
 * Clear local storage
 */
export const clear = () => {
  if (!isBrowser) return

  localStorage.clear()
}
