import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { setAppData, setAppLoading, setAppNoticeMsg } from './actions'

export const useApp = () => {
  const dispatch = useDispatch()
  const appReducer = useSelector(({ appReducer }) => appReducer, shallowEqual)

  return {
    ...appReducer,
    setAppData: (data) => dispatch(setAppData(data)),
    setAppLoading: (isLoading) => dispatch(setAppLoading(isLoading)),
    setAppNoticeMsg: (noticeData) => dispatch(setAppNoticeMsg(noticeData)),
  }
}
