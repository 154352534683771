import ApiRequest from 'services/api'
import toast from 'utils/toast'
import {
  API_GET_MY_LIST,
  API_GET_LIST_TOURS,
  API_ADD_MY_LIST,
  API_REMOVE_MY_LIST,
  API_ADD_TRACKING,
} from 'config/api'
import {
  GET_MY_LIST_FAVORITE,
  GET_LIST_TOURS,
  LIST_FAVORITE_ADD,
  LIST_FAVORITE_REMOVE,
  GO_TO_ELE_EXHIBITION_ID,
} from './actionTypes'
import moment from 'moment'
import { TRACKING } from 'config/constants/common'

/**
 * Get my list favorite
 */
export const getMyListFavorite = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    ApiRequest.get(
      API_GET_MY_LIST,
      {},
      (res) => {
        const { data } = res || {}
        const { errorCode, payload } = data || {}

        if (!errorCode) {
          dispatch({ type: GET_MY_LIST_FAVORITE, payload })
          resolve()
          return
        }
        reject({ errorCode })
      },
      (err) => reject(err)
    )
  })
}

/**
 * Get list tours
 */
export const getListTours = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    ApiRequest.get(
      API_GET_LIST_TOURS,
      {},
      (res) => {
        const { data } = res || {}
        const { errorCode, payload } = data || {}

        if (!errorCode) {
          dispatch({ type: GET_LIST_TOURS, payload })
          resolve()
          return
        }
        reject({ errorCode })
      },
      (err) => reject(err)
    )
  })
}

/**
 * Add webinar | exhibiton to my list favorite
 *
 * @param {String} type Query type exhibition | webinar
 * @param {Object} params Request params
 */
export const addToMyList = (params = {}, callbackSuccess) => (dispatch) => {
  return new Promise((resolve, reject) => {
    ApiRequest.post(
      API_ADD_MY_LIST,
      params,
      (res) => {
        const { data } = res || {}
        const { errorCode, payload: savedIds = [], errorMessage = '' } = data || {}

        if (errorCode) {
          toast.error(errorMessage)
          reject({ errorCode, errorMessage })
          return
        }
        typeof callbackSuccess === 'function' && callbackSuccess()
        dispatch({ type: LIST_FAVORITE_ADD, payload: { savedIds, itemData: params } })
        resolve()
      },
      (err) => {
        toast.error('error:lbl_something_went_wrong')
        reject(err)
      }
    )
  })
}

/**
 * Remove webinar | exhibiton from my list favorite
 *
 * @param {String} type Query type exhibition | webinar
 * @param {Object} params Request params
 */
export const removeFromMyList = (params = {}) => (dispatch) => {
  return new Promise((resolve, reject) => {
    ApiRequest.post(
      API_REMOVE_MY_LIST,
      params,
      (res) => {
        const { data } = res || {}
        const { errorCode, errorMessage = '' } = data || {}

        if (errorCode) {
          toast.error(errorMessage)
          reject({ errorCode, errorMessage })
          return
        }
        dispatch({ type: LIST_FAVORITE_REMOVE, payload: params })
        resolve()
      },
      (err) => {
        toast.error('error:lbl_something_went_wrong')
        reject(err)
      }
    )
  })
}

/**
 * Add tracking
 *
 * @param {Object} params Request params
 */
export const addTracking = (params = {}) => (dispatch) => {
  const timestamp = moment().format(TRACKING.TIMESTAMP_FORMAT)
  ApiRequest.post(API_ADD_TRACKING, { ...params, timestamp })
}

/**
 * Go to DOM Element exhibition id on list panels page
 *
 * @param {String} eleExhibitionId DOM Element Exhibition Id
 */
export const goToEleExhibitionId = (eleExhibitionId) => (dispatch) => {
  dispatch({ type: GO_TO_ELE_EXHIBITION_ID, payload: eleExhibitionId })
}
