import { SET_APP_DATA, SET_APP_LOADING, SET_APP_NOTICE_MSG } from './actionTypes'

/**
 * Set App data
 *
 * @param {Object} data App data
 */
export const setAppData = (data = {}) => (dispatch) => {
  dispatch({ type: SET_APP_DATA, payload: data })
}

/**
 * Set App loading effect
 *
 * @param {Boolean} isLoading Loading effect status
 */
export const setAppLoading = (isLoading) => (dispatch) => {
  dispatch({ type: SET_APP_LOADING, payload: isLoading })
}

/**
 * Set App notice msg data ({ title, message })
 *
 * @param {Object} noticeData Notice msg data
 */
export const setAppNoticeMsg = (noticeData = {}) => (dispatch) => {
  dispatch({ type: SET_APP_NOTICE_MSG, payload: noticeData })
}
