import React from 'react'
import { useTranslation } from 'react-i18next'
import { AppBar, Container } from '@material-ui/core'
import Button from 'components/widgets/Button'
import { getMailTo } from 'utils/common'
import { FOOTER_LINK_NEC, LANG_EN } from 'config/constants/common'
import './index.scss'

const socials = [
  {
    name: 'facebook',
    link: 'https://www.facebook.com/NECLabsJapan/',
  },
  {
    name: 'linkedin',
    link: 'https://www.linkedin.com/company/neclabsjapan/',
  },
]
const mails = [
  {
    label: 'event',
    address: 'oh2020_contact@rdu.jp.nec.com',
  },
  {
    label: 'web_system',
    address: 'oh2020_system@rdu.jp.nec.com',
  },
]

export default function Footer() {
  const { t, i18n } = useTranslation('home')
  const { language: currentLang = LANG_EN } = i18n || {}

  return (
    <AppBar className="Footer-area" position="static">
      <Container className="Footer-container">
        <div className="Footer-up">
          <div className="Footer-section Footer-about">
            <h5 className="Footer-title">{t('lbl_about_division_en')}</h5>
            <div className="Footer-sectionContainer">
              <Button
                className="Footer-link"
                label={t('lbl_nec_r&d_en')}
                href={FOOTER_LINK_NEC[currentLang]}
                target="_blank"
              />
            </div>
          </div>
          <div className="Footer-section Footer-contact">
            <h5 className="Footer-title">{t('lbl_inquiries')}</h5>
            <div className="Footer-sectionContainer">
              {mails.map((mailItem, index) => (
                <p className="Footer-mail" key={index}>
                  <span>{t(`lbl_inquiries_about_${mailItem.label}`)}:&nbsp;</span>
                  <a className="Footer-mail_address" href={getMailTo(mailItem.address)}>
                    {mailItem.address}
                  </a>
                </p>
              ))}
            </div>
          </div>
          <div className="Footer-section Footer-socials">
            <h5 className="Footer-title">{t('lbl_follow_us_en')}</h5>
            <div className="Footer-sectionContainer Footer-socials_list">
              {socials.map((social, index) => (
                <a
                  key={index}
                  href={social.link}
                  target="_blank"
                  rel="noreferrer"
                  className="Footer-socials_item"
                >
                  <span className={`Icon-ic Icon-${social.name} Footer-icon`} />
                </a>
              ))}
            </div>
          </div>
        </div>
        <p className="Footer-copyright">{t('lbl_copyright_en')}</p>
      </Container>
    </AppBar>
  )
}
