import { toUpper } from 'lodash'
import { ROLES } from 'config/constants'

/**
 * Get user avatar from user name
 *
 * @param {String} name User name
 */
export const getUserAvaFromName = (name = '') => {
  if (!name) return

  const arrStr = name.split(' ')
  const [firstText = '', secondText = ''] = arrStr

  return toUpper(`${firstText[0] || ''}${secondText[0] || ''}`)
}

/**
 * Format position and org name
 *
 * @param {String} position Position
 * @param {String} orgName Org name
 * @param {String} siparate Siparate
 */
export const formatPositionAndOrgName = (position = '', orgName = '', siparate = '\n') => {
  if (position && orgName) {
    return `${position}${siparate}${orgName}`
  }

  return position || orgName
}

/**
 * Check user is vip or not
 *
 * @param {Object} user User info
 */
export const isVip = (user) => {
  const { type = '' } = user || {}

  return ROLES.VIP === type
}

/**
 * Check user is presenter or not
 *
 * @param {Object} user User info
 */
export const isPresenter = (user) => {
  const { type = '' } = user || {}

  return ROLES.PRESENTER === type
}

/**
 * Get list users name
 *
 * @param {Array} users Users
 * @param {String} keyData Key wanna get userData, Example ecmsEventVipByVipId
 */
export const getListUsersName = (users = [], keyData = '') => {
  const listNames = users.reduce((listNames, user) => {
    const userData = user[keyData] || {}
    const { displayName = '' } = userData || {}

    return [...listNames, displayName]
  }, [])

  return listNames.join(', ')
}
