import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import './index.scss'

export default function AnimateLink(props) {
  const { children = '', className = '', ...rest } = props

  return (
    <AniLink
      {...rest}
      paintDrip
      hex="#fff"
      duration={0.7}
      className={`AnimateLink-link ${className}`}
    >
      {children}
    </AniLink>
  )
}
