import axios from 'axios'
import moment from 'moment'
import { configureStore } from 'store/store'
import * as storage from 'utils/localStorage'
import { requestRefreshToken as fnRefreshToken } from 'store/Auth/actions'
import {
  LS_TOKEN,
  LS_EVENT_ID,
  REQ_ERROR_CODES,
  DEFAULT_EXPIRE_TOKEN,
} from 'config/constants/common'

const ApiRequest = {}

/**
 * Get header configs
 */
function getHeaderConfigs() {
  return {
    'Content-Type': 'application/json',
  }
}

/**
 * Process refresh token
 *
 * @param {Object} res Request response
 * @param {Object} requestOptions Request options use for refresh token
 */
// eslint-disable-next-line no-unused-vars
async function requestRefreshToken(res = {}, requestOptions = {}) {
  const { data } = res || {}
  const { errorCode } = data || {}

  if (errorCode === REQ_ERROR_CODES.unauthorized) {
    try {
      const tokenEncode = storage.getItem(LS_TOKEN)
      const tokenData = JSON.parse(tokenEncode) || {}
      const { refreshToken = '', expireToken = DEFAULT_EXPIRE_TOKEN } = tokenData
      const now = moment()

      if (now > expireToken) {
        await configureStore.dispatch(fnRefreshToken({ refreshToken }))

        // execute previous request again
        const { method, url, params = {}, options = {} } = requestOptions || {}
        fetchAxios(method, url, params, options)
      }
    } catch (e) {
      // Exception
    }
  }
}

/**
 * Common fetch axios
 *
 * @param {String} method Request method
 * @param {String} url Request URL
 * @param {Object} params Request params
 * @param {Object} options Request options
 */
function fetchAxios(method, url, params = {}, options = {}) {
  const eventId = parseInt(storage.getItem(LS_EVENT_ID))

  const { onSuccess, onError } = options || {}
  const commonConfigs = {
    method,
    url: `${url}?eventId=${eventId}`,
    headers: getHeaderConfigs(),
  }
  const axiosConfigs =
    method === 'get' ? { ...commonConfigs, params } : { ...commonConfigs, data: params }
  const axiosRequest = axios(axiosConfigs)

  // use Promise request
  if (typeof onSuccess !== 'function') {
    return new Promise((resolve, reject) => {
      axiosRequest
        .then((res) => {
          resolve(res)
        })
        .catch((error) => reject(error))
    })
  }

  // use callback
  return axiosRequest
    .then((res) => {
      if (typeof onSuccess === 'function') {
        onSuccess(res)
      }
    })
    .catch((error) => {
      if (typeof onError === 'function') {
        onError(error)
      }
    })
}

/**
 * Request GET data
 *
 * @param {String} url Request URL
 * @param {Object} params Request params
 * @param {Function} onSuccess Function handle success callback
 * @param {Function} onError Function handle error callback
 */
ApiRequest.get = (url, params = {}, onSuccess, onError) => {
  return fetchAxios('get', url, params, { onSuccess, onError })
}

/**
 * Request POST data
 *
 * @param {String} url Request URL
 * @param {Object} params Request params
 * @param {Function} onSuccess Function handle success callback
 * @param {Function} onError Function handle error callback
 */
ApiRequest.post = (url, params = {}, onSuccess, onError) => {
  return fetchAxios('post', url, params, { onSuccess, onError })
}

/**
 * Request PUT data
 *
 * @param {String} url Request URL
 * @param {Object} params Request params
 * @param {Function} onSuccess Function handle success callback
 * @param {Function} onError Function handle error callback
 */
ApiRequest.put = (url, params = {}, onSuccess, onError) => {
  return fetchAxios('put', url, params, { onSuccess, onError })
}

/**
 * Request DELETE data
 *
 * @param {String} url Request URL
 * @param {Object} params Request params
 * @param {Function} onSuccess Function handle success callback
 * @param {Function} onError Function handle error callback
 */
ApiRequest.delete = (url, params = {}, onSuccess, onError) => {
  return fetchAxios('delete', url, params, { onSuccess, onError })
}

/**
 * Request GET data
 *
 * @param {String} url Request URL
 * @param {Object} params Request params
 */
ApiRequest.apiGet = (url, params = {}) => {
  return fetchAxios('get', url, params)
}

/**
 * Request POST data
 *
 * @param {String} url Request URL
 * @param {Object} params Request params
 */
ApiRequest.apiPost = (url, params = {}) => {
  return fetchAxios('post', url, params)
}

/**
 * Request PUT data
 *
 * @param {String} url Request URL
 * @param {Object} params Request params
 */
ApiRequest.apiPut = (url, params = {}) => {
  return fetchAxios('put', url, params)
}

/**
 * Request DELETE data
 *
 * @param {String} url Request URL
 * @param {Object} params Request params
 */
ApiRequest.apiDelete = (url, params = {}) => {
  return fetchAxios('delete', url, params)
}

/**
 * Request FILE data
 *
 * @param {String} url Request URL
 */
ApiRequest.requestFile = (url) => {
  return axios.get(url, { 
    responseType: 'blob'
  });
}

/**
 * Request FILE data
 *
 * @param {String} url Request URL
 */
ApiRequest.requestTracking = (url, params = {}) => {
  const commonConfigs = {
    method: 'post',
    url,
    data: params,
  }

  const axiosRequest = axios(commonConfigs)
  return axiosRequest
}

export default ApiRequest
