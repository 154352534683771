import moment from 'moment'
import { SET_AUTH_DATA, LOG_OUT, SET_SERVICE_AZURE, SET_CLIENT_TZ_DATA } from './actionTypes'
import * as storage from 'utils/localStorage'
import { getCountryFromTz } from 'utils'
import { LS_TOKEN, DEFAULT_EXPIRE_TOKEN } from 'config/constants/common'

const initialState = {
  tokenData: {
    accessToken: '',
    refreshToken: '',
    expireToken: moment() + DEFAULT_EXPIRE_TOKEN,
  },
  authData: {},
  clientTzData: getCountryFromTz(),
  isAzureAuth: false, // use azure services authenticate or not
}

const authReducer = (state = initialState, action) => {
  const { type, payload: response } = action

  switch (type) {
    case SET_AUTH_DATA: {
      const { tokenData: oldTokenData, authData: oldAuthData } = state
      const {
        accessToken = '',
        refreshToken = '',
        expiresIn: expireToken = DEFAULT_EXPIRE_TOKEN,
        ...authData
      } = response

      const tokenData = {
        accessToken: accessToken || oldTokenData.accessToken,
        refreshToken: refreshToken || oldTokenData.refreshToken,
        expireToken: moment() + expireToken * 1000 || oldTokenData.expireToken,
      }

      if (accessToken) storage.setItem(LS_TOKEN, JSON.stringify(tokenData))

      return {
        ...state,
        tokenData,
        authData: { ...oldAuthData, ...authData },
      }
    }
    case SET_SERVICE_AZURE: {
      return {
        ...state,
        isAzureAuth: response,
      }
    }
    case SET_CLIENT_TZ_DATA: {
      return {
        ...state,
        clientTzData: response,
      }
    }
    case LOG_OUT: {
      storage.removeItem(LS_TOKEN)

      return initialState
    }
    default:
      return state
  }
}

export default authReducer
