import React from 'react'
import Header from './Header'
import Footer from '../Footer'
import './index.scss'

export default function LayoutTour(props) {
  const { children, isTransparentHeader = false, isUseFooter = true, pageContext } = props
  const {
    isTransparentHeader: contextTranparentHeader = false,
    isUseFooter: contextUseFooter = true,
  } = pageContext || {}
  const transparentHeader = isTransparentHeader || contextTranparentHeader
  const useFooter = isUseFooter && contextUseFooter

  return (
    <div className="LayoutTour-container">
      <div className="LayoutTour-content">
        <Header {...props} isTransparent={transparentHeader} />
        <div className={`LayoutTour-mainContainer ${!transparentHeader && 'padding'}`}>
          {children}
        </div>
      </div>
      {useFooter && <Footer />}
    </div>
  )
}
