import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Menu, MenuItem, Avatar } from '@material-ui/core'
import { navigate } from 'gatsby'
import { useAuth } from 'store/Auth'
import { isPresenter as checkPresenter, isVip as checkVip } from 'utils'
import toast from 'utils/toast'
import { IS_USE_LOGOUT } from 'config/constants/common'
import './index.scss'

const ProfileMenu = (props) => {
  const { t } = useTranslation(['home', 'error'])
  const { authData, logoutAzureAD } = useAuth()
  const { anchorElProfile, setAnchorElProfile } = props || {}
  const { displayName = '', orgName = '' } = authData || {}
  const isPresenter = checkPresenter(authData)
  const isVip = checkVip(authData)

  /**
   * Handle logout
   */
  async function handleLogout() {
    handleCloseMenu()
    try {
      const res = await logoutAzureAD()
      const { redirectUrl = '/' } = res || {}

      if (typeof window !== 'undefined') {
        window.location.href = redirectUrl
      }
    } catch (e) {
      // Exeption
      toast.error('error:lbl_something_went_wrong')
    }
  }

  const handleClickMenu = (event) => {
    setAnchorElProfile(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorElProfile(null)
  }

  /**
   * Go to profile page
   *
   * @param {String} option Option view
   */
  function handleNavigate(pathNavigate) {
    handleCloseMenu()
    navigate(`/${pathNavigate}`)
  }

  return (
    <Fragment>
      <Avatar className="profile_menu-avatar" onClick={handleClickMenu} />
      <Menu
        anchorEl={anchorElProfile}
        open={!!anchorElProfile}
        onClose={handleCloseMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className="profile_menu-dropdown"
      >
        <MenuItem className="profile_menu-info" disabled>
          <Avatar className="profile_menu-info-avatar" />
          <div className="profile_menu-info-user">
            <div>
              <p className="profile_menu-info-user-name">{displayName}</p>
            </div>
            <div>
              <p className="profile_menu-info-user-organisation">{orgName}</p>
            </div>
          </div>
        </MenuItem>
        <hr />
        {isPresenter && (
          <div>
            <MenuItem className="profile_menu-item" onClick={() => handleNavigate('presenter')}>
              {t('lbl_presentation_en')}
            </MenuItem>
          </div>
        )}
        {isVip && (
          <div>
            <MenuItem className="profile_menu-item" onClick={() => handleNavigate('VIPwelcome')}>
              {t('lbl_vip_welcome_en')}
            </MenuItem>
          </div>
        )}
        <div>
          <MenuItem className="profile_menu-item" onClick={() => handleNavigate('mylist')}>
            {t('lbl_my_list_en')}
          </MenuItem>
        </div>
        {IS_USE_LOGOUT && (
          <div>
            <hr />
            <MenuItem id="logout" onClick={handleLogout} className="profile_menu-item">
              {t('lbl_profile_signout_en')}
            </MenuItem>
          </div>
        )}
      </Menu>
    </Fragment>
  )
}

export default ProfileMenu
