import {
  GET_MY_LIST_FAVORITE,
  GET_LIST_TOURS,
  LIST_FAVORITE_ADD,
  LIST_FAVORITE_REMOVE,
  LOG_OUT,
  GO_TO_ELE_EXHIBITION_ID,
} from './actionTypes'

const initialState = {
  listFavorite: [],
  listTours: [],
  eleExhibitionId: null,
}

const referenceReducer = (state = initialState, action) => {
  const { type, payload: response } = action

  switch (type) {
    case GET_MY_LIST_FAVORITE: {
      return {
        ...state,
        listFavorite: response || [],
      }
    }
    case GET_LIST_TOURS: {
      return {
        ...state,
        listTours: response || [],
      }
    }
    case LIST_FAVORITE_ADD: {
      const listFavorite = [...state.listFavorite]
      const { savedIds, itemData } = response || {}

      savedIds.forEach(({ savedId }) => listFavorite.push({ ...itemData, savedId }))

      return {
        ...state,
        listFavorite,
      }
    }
    case LIST_FAVORITE_REMOVE: {
      let listFavorite = [...state.listFavorite]
      const { savedIds } = response || {}

      savedIds.forEach((savedId) => {
        listFavorite = listFavorite.filter((favoriteItem) => favoriteItem.savedId !== savedId)
      })

      return {
        ...state,
        listFavorite,
      }
    }
    case GO_TO_ELE_EXHIBITION_ID: {
      return {
        ...state,
        eleExhibitionId: response,
      }
    }
    case LOG_OUT: {
      return initialState
    }
    default:
      return state
  }
}

export default referenceReducer
