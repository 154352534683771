import { GET_LIST_WEBINARS_VIEWS, GET_LIST_PANELS_VIEWS, GET_TOTAL_ANALYSIS } from './actionTypes'

const initialState = {
  totalAnalysis: {},
  webinarsViews: {},
  panelsViews: {},
}

const analysisReducer = (state = initialState, action) => {
  const { type, payload: response } = action

  switch (type) {
    case GET_LIST_WEBINARS_VIEWS: {
      return {
        ...state,
        webinarsViews: response,
      }
    }
    case GET_LIST_PANELS_VIEWS: {
      return {
        ...state,
        panelsViews: response,
      }
    }
    case GET_TOTAL_ANALYSIS: {
      return {
        ...state,
        totalAnalysis: response,
      }
    }
    default:
      return state
  }
}

export default analysisReducer
