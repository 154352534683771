import React from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@material-ui/core'
import 'utils/i18n'
import { toast } from 'react-toastify'
import { configureStore } from '../../store'
import muiTheme from 'config/mui.theme'
import AppLoadingWrap from './AppLoadingWrap'
import 'bootstrap/dist/css/bootstrap.min.css'

toast.configure({
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  pauseOnFocusLoss: false,
  newestOnTop: true,
  limit: 3,
})

export default ({ element }) => (
  <Provider store={configureStore}>
    <ThemeProvider theme={muiTheme}>
      <AppLoadingWrap>{element}</AppLoadingWrap>
    </ThemeProvider>
  </Provider>
)
