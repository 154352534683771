import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
  getMyListFavorite,
  getListTours,
  addToMyList,
  removeFromMyList,
  addTracking,
  goToEleExhibitionId,
} from './actions'
import { useAuth } from 'store/Auth'

export const useReference = () => {
  const dispatch = useDispatch()
  const { authData = {} } = useAuth()
  const { department: userDepartment = '' } = authData || {}
  const referenceReducer = useSelector(({ referenceReducer }) => referenceReducer, shallowEqual)

  return {
    ...referenceReducer,
    getListTours: () => dispatch(getListTours()),
    getMyListFavorite: () => dispatch(getMyListFavorite()),
    addToMyList: (params, callbackSuccess) => dispatch(addToMyList(params, callbackSuccess)),
    removeFromMyList: (params) => dispatch(removeFromMyList(params)),
    addTracking: (params) => dispatch(addTracking({ ...params, userDepartment })),
    goToEleExhibitionId: (eleExhibitionId) => dispatch(goToEleExhibitionId(eleExhibitionId)),
  }
}
