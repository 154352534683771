const isBrowser = typeof window !== 'undefined'

/**
 * Download file
 * @param {Blob} data File data
 * @param {String} fileName File name
 * @param {String} fileType File type
 */
export const downloadFile = (data = null, fileName = '', fileType = '') => {
  if (!data || !isBrowser) return

  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', getFileName(fileName, fileType))
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const getFileName = (fileName = '', extendsion = '') => {
  const strArr = fileName.split('.')
  if (strArr[strArr.length - 1] === extendsion) {
    return fileName
  } else {
    return `${fileName}.${extendsion}`
  }
}
