export const API_URL = process.env.GATSBY_API_URL

export const API_LOGIN = `${API_URL}/user/login`
export const API_RELOGIN = `${API_URL}/user/re-login`
export const API_FORCE_PASSWORD = `${API_URL}/user/force-change-password`
export const API_REFRESH_TOKEN = `${API_URL}/user/refresh-token`
export const API_LOGIN_AZURE_AD = '/amzn-oidc'
export const API_LOGOUT_AZURE_AD = `${API_URL}/user/logout`
// PROFILE
export const API_GET_LIST_TOURS = `${API_URL}/tour/list`
export const API_GET_MY_LIST = `${API_URL}/mylist/list`
export const API_ADD_MY_LIST = `${API_URL}/mylist/add`
export const API_REMOVE_MY_LIST = `${API_URL}/mylist/remove`
//TRACKING
export const API_ADD_TRACKING = `${API_URL}/collect`
// ANALYSIS
export const API_GET_LIST_WEBINARS_VIEWS = `${API_URL}/counter/webinars-views`
export const API_GET_LIST_PANELS_VIEWS = `${API_URL}/counter/panels-views`
export const API_GET_TOTAL_ANALYSIS = `${API_URL}/counter/total-analysis`
