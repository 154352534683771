import { REQ_ERROR_CODES } from 'config/constants/common'

/**
 * Get msg error by error code
 *
 * @param {Integer} errorCode Error code get from server
 */
export const getMsgError = (errorCode = 1) => {
  switch (errorCode) {
    case REQ_ERROR_CODES.invalidRequest:
      return 'error:lbl_invalid_request'
    case REQ_ERROR_CODES.failedQuery:
      return 'error:lbl_failed_query'
    case REQ_ERROR_CODES.loginCognitoUnknow:
      return 'error:lbl_unknown_login'
    case REQ_ERROR_CODES.unauthorized:
      return 'error:lbl_unauthorized'
    case REQ_ERROR_CODES.invalidUserRole:
      return 'error:lbl_invalid_role'
    case REQ_ERROR_CODES.loginUserInactive:
      return 'error:lbl_user_inactive'
    case REQ_ERROR_CODES.loginUserNotExist:
      return 'error:lbl_user_not_exists'
    case REQ_ERROR_CODES.loginUserType:
      return 'error:lbl_user_invalid_type'
    case REQ_ERROR_CODES.errUserHasNoEmail:
      return 'error:lbl_user_has_no_email'
    case REQ_ERROR_CODES.errUserHasNoOrg:
      return 'error:lbl_user_has_no_organization'
    default:
      return ''
  }
}
