import React, { useEffect } from 'react'
import { useApp } from 'store/Application'
import AppLoading from 'components/AppLoading/AppLoading'

export default function AppLoadingWrap({ children }) {
  const { isAppLoading } = useApp()

  useEffect(() => {
    if (isAppLoading) {
      document.body.classList.add('fixed')
      return
    }
    document.body.classList.remove('fixed')
  }, [isAppLoading])

  return (
    <>
      {isAppLoading && <AppLoading />}
      <>{children}</>
    </>
  )
}
